import { useCallback } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { BasicEntryData, BasicEntryDataWithType, SquaresGameData } from "@sportsball/shared";

import SquaresBoard from "./SquaresBoard";

import { useUser } from "@/context/useUser";
import { GameRoomData } from "../GameContainer";

export default function SquaresBoardEditor({
  show,
  doneEditing,
  initials,
  setInitials,
  gameId,
  game,
  roomData,
  pregameEntries,
}: {
  show: boolean;
  doneEditing: () => void;
  initials: string;
  setInitials: (initials: string) => void;
  gameId: string;
  game: SquaresGameData;
  roomData: GameRoomData | undefined;
  pregameEntries?: Partial<Record<string, BasicEntryData>>;
}) {
  const { user, firebase } = useUser();
  const uid = user?.uid;

  const canAffordASquare = !roomData || (roomData.chipBalance ?? 0) >= (game.chipsPerEntry ?? 0);
  const validInitials = initials.length > 0 && !someoneElseHasInitials(initials);

  const handleClaimSquare = useCallback(
    async ({ cellEntryId }: { cellEntryId: string }) => {
      if (!firebase || !uid || !validInitials) {
        return;
      }

      try {
        const { deleteDoc, doc, setDoc, updateDoc } = firebase.firestorePackage;
        const pregameEntryRef = doc(firebase.firestore, `games/${gameId}/pregame-entries/${cellEntryId}`);

        if (pregameEntries?.[cellEntryId]?.uid === uid) {
          if (game.inRoom && game.chipsPerEntry) {
            const roomDoc = doc(firebase.firestore, `rooms/${game.inRoom}`);
            await updateDoc(roomDoc, {
              [`members.${uid}.chipCount`]: firebase.firestorePackage.increment(game.chipsPerEntry),
            });
          }
          await deleteDoc(pregameEntryRef);
        } else if (!pregameEntries?.[cellEntryId]) {
          if (!canAffordASquare) {
            alert("You don't have enough chips to claim this square");
            return;
          }
          if (game.inRoom && game.chipsPerEntry) {
            const roomDoc = doc(firebase.firestore, `rooms/${game.inRoom}`);
            await updateDoc(roomDoc, {
              [`members.${uid}.chipCount`]: firebase.firestorePackage.increment(-game.chipsPerEntry),
            });
          }
          const cellEntryData: BasicEntryDataWithType = {
            gameType: "Squares",
            uid,
            entryName: initials,
          };
          await setDoc(pregameEntryRef, cellEntryData);
        }
      } catch (error) {
        alert(error);
      }
    },
    [firebase, game, gameId, uid, pregameEntries, initials, validInitials, canAffordASquare]
  );

  function someoneElseHasInitials(initials: string) {
    return Object.values(pregameEntries ?? {}).some((entry) => entry?.entryName === initials && entry?.uid !== uid);
  }

  function processInitials(initials: string) {
    const segmenter = new Intl.Segmenter("en", { granularity: "grapheme" });
    const segments = Array.from(segmenter.segment(initials)).map((s) => s.segment);
    return segments.slice(0, 3).join("");
  }

  return (
    <Dialog
      open={show}
      onClose={doneEditing}
      sx={{
        userSelect: "none",
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "none",
          margin: 2,
        },
      }}
    >
      <DialogTitle>Make your Picks</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <DialogContentText>Choose your initials then tap a square to claim it. Tap again to clear.</DialogContentText>
          <TextField
            label="Initials"
            sx={{ mt: 1 }}
            value={initials}
            onChange={(e) => setInitials(processInitials(e.target.value ?? ""))}
            error={!validInitials}
            helperText={!initials ? "Initials required" : someoneElseHasInitials(initials) ? "Already taken" : ""}
            required
          />
        </Stack>
      </DialogContent>
      {}
      <SquaresBoard
        game={game}
        gameOn={false}
        pregameEntries={pregameEntries}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        handleClaimSquare={handleClaimSquare}
        constrainHeight={true}
      />
      <DialogActions>
        <Button onClick={doneEditing}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
