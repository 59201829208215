import { useRef, useState, useEffect } from "react";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  getCellId,
  isStartedSquaresGame,
  singleSportsGame,
  BasicEntryData,
  SquaresGameData,
  teamLabel,
  getSquaresGameStatus,
  periodTargetScores,
  isSportsGameActive,
  getTotalScore,
  isPregame,
  MultiTargetScore,
} from "@sportsball/shared";

import { InitialsProps, TitleProps } from "@/style";

import SquaresScoreboard from "./SquaresScoreboard";
import { DarkGridGuideColor, LightGridGuideColor } from "./SquaresBoardColors";
import TargetScore from "./TargetScore";

import { useUser } from "@/context/useUser";
import deepEqual from "deep-equal";

export default function SquaresBoard({
  game,
  gameOn,
  constrainHeight,
  pregameEntries,
  handleClaimSquare,
}: {
  game: SquaresGameData;
  gameOn: boolean;
  constrainHeight?: boolean;
  pregameEntries?: Partial<Record<string, BasicEntryData>>;
  handleClaimSquare?: ({ cellEntryId }: { cellEntryId: string }) => void;
}) {
  const { size } = game;
  const { user } = useUser();
  const uid = user?.uid;

  const sportsGame = singleSportsGame(game.sportsGames);
  if (!sportsGame) {
    throw new Error("Squares game without football game");
  }
  const gameLocked = isStartedSquaresGame(game);
  const isEditing = !gameLocked && Boolean(handleClaimSquare);

  const entries = isStartedSquaresGame(game) ? game.lockedEntries : pregameEntries;

  const sizeDimension = size === 25 ? 5 : 10;
  const _dimension = [...Array(sizeDimension).keys()];

  const gameStatus = isStartedSquaresGame(game) ? getSquaresGameStatus(game) : undefined;
  let periodIndex: number | undefined;
  if (isPregame(game)) {
    periodIndex = 0;
  } else {
    periodIndex = (sportsGame.periodScores?.length ?? 1) - 1;
  }
  const targetScores = game.targetScores
    ? periodTargetScores({
        targetScores: game.targetScores,
        changeTargetScores: game.changeTargetScores,
        periodIndex: periodIndex ?? 0,
      })
    : undefined;
  const totalScore = getTotalScore(sportsGame);

  const periodResults = gameStatus?.periodResults ?? [];
  const activeCell =
    periodResults.length > 0 && isSportsGameActive(sportsGame)
      ? periodResults[periodResults.length - 1].rowCol
      : undefined;

  const awayScoreRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [animationPhase, setAnimationPhase] = useState(0);
  const [displayScores, setDisplayScores] = useState(targetScores);
  const [targetDisplayScores, setTargetDisplayScores] = useState(targetScores);

  function getTargetDigits(targetScores: number | MultiTargetScore | undefined) {
    if (targetScores === undefined) {
      return size === 25 ? [undefined, undefined] : [undefined];
    }
    if (typeof targetScores === "number") {
      return [targetScores % 10];
    }
    return targetScores?.multi?.map((d) => d % 10) ?? [];
  }

  useEffect(() => {
    if (JSON.stringify(targetScores) !== JSON.stringify(targetDisplayScores)) {
      // Phase 1: Move current score out
      setAnimationPhase(1);

      // After first animation completes, update scores and start phase 2
      setTimeout(() => {
        setDisplayScores(targetScores);
        setTargetDisplayScores(targetScores);
        setAnimationPhase(2);

        // After second animation completes, reset to normal state
        setTimeout(() => {
          setAnimationPhase(0);
        }, 250);
      }, 250);
    }
  }, [targetScores, targetDisplayScores]);

  function getDisplayScores() {
    return displayScores;
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrolledElement = event.target as HTMLDivElement;
    const scrollTop = scrolledElement.scrollTop;

    if (scrolledElement === awayScoreRef.current && gridRef.current) {
      gridRef.current.scrollTop = scrollTop;
    } else if (scrolledElement === gridRef.current && awayScoreRef.current) {
      awayScoreRef.current.scrollTop = scrollTop;
    }
  };

  function homeTeamTitle() {
    return (
      <Typography variant="h5" align="center" padding={1} {...TitleProps}>
        {teamLabel(sportsGame.teams, "home")}
        {totalScore && ` (${totalScore.home})`}
      </Typography>
    );
  }

  function awayTeamTitle() {
    return (
      <Typography
        variant="h5"
        align="center"
        padding={1}
        {...TitleProps}
        sx={{ writingMode: "vertical-lr", transform: "rotate(180deg)", marginLeft: -3 }}
      >
        {teamLabel(sportsGame.teams, "away")}
        {totalScore && ` (${totalScore.away})`}
      </Typography>
    );
  }

  function awayScoreCol() {
    const scoreColumn = (
      <Grid container columns={1} sx={{ maxWidth: size === 25 ? "50px" : "80px" }}>
        <TargetScore
          targetDigits={[]}
          score={undefined}
          visibility={"hidden"}
          sportsGame={sportsGame}
          animationPhase={animationPhase}
        />
        {_dimension.map((i) => (
          <TargetScore
            key={i}
            targetDigits={getTargetDigits(getDisplayScores()?.away?.[i])}
            score={totalScore?.away}
            sportsGame={sportsGame}
            isAway={true}
            animationPhase={animationPhase}
          />
        ))}
      </Grid>
    );

    if (size === 100) {
      return (
        <Box
          ref={awayScoreRef}
          overflow="auto"
          onScroll={handleScroll}
          sx={{
            touchAction: "auto",
            maxHeight: constrainHeight && isMobile ? "300px" : undefined,
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
          }}
        >
          {scoreColumn}
        </Box>
      );
    }

    return scoreColumn;
  }

  function homeScoreRow() {
    return _dimension.map((i) => (
      <TargetScore
        key={i}
        targetDigits={getTargetDigits(getDisplayScores()?.home?.[i])}
        score={totalScore?.home}
        sportsGame={sportsGame}
        isAway={false}
        animationPhase={animationPhase}
      />
    ));
  }

  function boardRow(row: number) {
    return _dimension.map((col) => {
      const rowCol = { row, col };
      const cellEntryId = getCellId(rowCol);
      const cellEntry = entries?.[cellEntryId];
      const initials = cellEntry?.entryName ?? "-";
      const cellWinLabels =
        gameStatus?.periodResults.filter((w) => deepEqual(w.rowCol, rowCol)).map((w) => w.label) ?? [];

      const matchesAway = targetScores && activeCell && activeCell.row === row;
      const matchesHome = targetScores && activeCell && activeCell.col === col;
      const activeWinner = matchesAway && matchesHome;
      const isEditable = (!gameLocked && !cellEntry) || cellEntry?.uid === uid;
      const bgcolor = activeWinner
        ? DarkGridGuideColor
        : (matchesAway || matchesHome) && !animationPhase
        ? LightGridGuideColor
        : !isEditable && isEditing
        ? "lightgray"
        : undefined;

      return (
        <Grid
          key={col}
          position="relative"
          size={1}
          paddingY={1}
          textAlign="center"
          onClick={() => {
            if (!isEditable) {
              return;
            }
            handleClaimSquare?.({ cellEntryId });
          }}
          borderBottom={2}
          borderRight={2}
          bgcolor={bgcolor}
          sx={{ transition: "background-color 450ms ease-in-out" }}
        >
          <Typography
            {...InitialsProps}
            fontWeight={activeWinner ? "900" : "400"}
            visibility={cellEntry ? "visible" : "hidden"}
            sx={{
              opacity: isEditable || !isEditing ? 1 : 0.5,
              transition: "font-weight 450ms ease-in-out",
            }}
          >
            <span style={{ whiteSpace: "nowrap" }}>{initials}</span>
          </Typography>
          {cellWinLabels.length > 0 && (
            <Typography position="absolute" fontSize={8} bottom={0} left={2} fontWeight="bold" color="red">
              {cellWinLabels.join(" ")}
            </Typography>
          )}
        </Grid>
      );
    });
  }

  function squaresGrid() {
    function theGrid(columns: number) {
      return (
        <Grid container columns={columns} width="100%">
          {homeScoreRow()}
          {_dimension.map((row) => boardRow(row))}
        </Grid>
      );
    }

    if (size === 100) {
      return (
        <Box
          ref={gridRef}
          overflow="auto"
          onScroll={handleScroll}
          sx={{
            touchAction: "auto",
            maxHeight: constrainHeight && isMobile ? "300px" : undefined,
          }}
        >
          <Box width={500}>{theGrid(10)}</Box>
        </Box>
      );
    }
    return theGrid(5);
  }

  /*
  function squaresLeaderboard() {
    if (!gameStatus?.periodResults || !entries) {
      return null;
    }

    const winCounts = new Map<string, number>();

    const periodResults = [...gameStatus.periodResults];
    if (isGamePeriodActive(sportsGame)) {
      periodResults.pop();
    }

    periodResults.forEach((result) => {
      const cellId = getCellId(result.rowCol);
      const entry = entries[cellId];
      if (entry?.entryName) {
        winCounts.set(entry.entryName, (winCounts.get(entry.entryName) || 0) + 1);
      }
    });

    const sortedPlayers = Array.from(winCounts.entries()).sort(([, a], [, b]) => b - a);

    if (sortedPlayers.length === 0) {
      return null;
    }

    return (
      <Box ml={2} minWidth={80}>
        <Typography variant="subtitle2" fontWeight="bold">
          Leaders:
        </Typography>
        {sortedPlayers.map(([initials, wins]) => (
          <Typography key={initials} variant="body2">
            {initials}: {wins}
          </Typography>
        ))}
      </Box>
    );
  }
    */

  return (
    <>
      {gameOn && <SquaresScoreboard game={game} gameStatus={gameStatus} />}
      <Stack>
        {!isEditing && homeTeamTitle()}
        <Stack direction="row" justifyContent="center">
          {!isEditing && awayTeamTitle()}
          {awayScoreCol()}
          {squaresGrid()}
        </Stack>
      </Stack>
    </>
  );
}
