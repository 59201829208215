import { useEffect, useMemo, useState } from "react";
import { keyframes } from "@mui/system";

import {
  BasicEntryData,
  generateTargetScores,
  getCellId,
  SquaresGameData,
  squaresSportsGame,
  SportsGames,
} from "@sportsball/shared";

import { NewSquaresGameData } from "./NewSquaresGame";
import SquaresBoard from "@/components/squares/SquaresBoard";

import Box from "@mui/material/Box";
import { logAnalyticsEvent, AnalyticsEvents } from "@/lib/analytics";
import { useUser } from "@/context/useUser";
import { DEMO_SCRIPTS } from "./DemoScripts";

import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

function _generateInitials() {
  // random name initials created from all upper case characters except Q, X, Y, Z. Two or three
  // characters long. generated straight from github copilot
  // two 2/3 of the time and three 1/3 of the time
  const initialsLength = Math.random() < 0.33 ? 3 : 2;
  const initials = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    .replace(/[QXYZ]/g, "")
    .split("")
    .sort(() => Math.random() - 0.5)
    .slice(0, initialsLength)
    .join("");
  return initials;
}

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export default function DemoSquaresGame({ gameData, isPlaying }: { gameData: NewSquaresGameData; isPlaying: boolean }) {
  const { firebase, user } = useUser();

  // Add a key state that changes when isPlaying changes
  const [animationKey, setAnimationKey] = useState(0);

  // Update the key when isPlaying changes to force remount of the animation
  useEffect(() => {
    setAnimationKey((prev) => prev + 1);
  }, [isPlaying]);

  const sportsGame = useMemo(() => squaresSportsGame(gameData.sportsGames), [gameData.sportsGames]);
  const script = useMemo(() => DEMO_SCRIPTS[sportsGame.type], [sportsGame.type]);

  const [currentFrameIndex, setCurrentFrameIndex] = useState(0);

  const { size } = gameData;
  const { type: sport } = sportsGame;

  const lockedEntries = useMemo(() => {
    const lockedEntries: Record<string, BasicEntryData> = {};
    for (let row = 0; row < Math.sqrt(size); row++) {
      for (let col = 0; col < Math.sqrt(size); col++) {
        const cell = getCellId({ row, col });
        lockedEntries[cell] = { uid: cell, entryName: _generateInitials() };
      }
    }
    return lockedEntries;
  }, [size]);

  const targetScores = useMemo(() => generateTargetScores(size, sport), [size, sport]);

  const demoGame = useMemo((): SquaresGameData | undefined => {
    if (script.length === 0) {
      return;
    }

    // Get the current frame for the sport type
    const frame = DEMO_SCRIPTS[sportsGame.type][currentFrameIndex];

    // Create the sports games object
    const demoSportsGames = {
      type: sportsGame.type,
      games: {
        [sportsGame.id]: {
          ...sportsGame,
          ...frame,
          type: sportsGame.type,
        },
      },
    } as SportsGames;

    // Return the complete game object
    return {
      ...gameData,
      targetScores: [targetScores],
      startTimestamp: 0,
      name: "",
      id: "example-game",
      uid: "",
      code: "",
      description: "",
      lockedEntries,
      userRefs: [],
      users: {},
      sportsGames: demoSportsGames,
    };
  }, [gameData, lockedEntries, targetScores, script, currentFrameIndex, sportsGame]);

  // Add timer effect to cycle through frames
  useEffect(() => {
    if (script.length === 0 || !isPlaying) {
      return;
    }

    const timer = setInterval(() => {
      setCurrentFrameIndex((prev) => (prev + 1) % script.length);
    }, 2000);

    return () => clearInterval(timer);
  }, [script, isPlaying]);

  useEffect(() => {
    logAnalyticsEvent(firebase, user, AnalyticsEvents.DEMO_SQUARES_GAME_VIEW, {
      sport: sportsGame.type,
      size: gameData.size,
    });
  }, [sportsGame.type, gameData.size, firebase, user]);

  if (!demoGame) {
    return null;
  }

  return (
    <Box sx={{ position: "relative" }}>
      <SquaresBoard game={demoGame} gameOn={true} />
      <Box
        key={animationKey}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          borderRadius: "50%",
          padding: 2,
          animation: `${fadeOut} 1s ease-out forwards`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isPlaying ? (
          <PlayArrowIcon sx={{ fontSize: 60, color: "white" }} />
        ) : (
          <PauseIcon sx={{ fontSize: 60, color: "white" }} />
        )}
      </Box>
    </Box>
  );
}
