import CheckBoxIcon from "@mui/icons-material/CheckBox";
import GridOnIcon from "@mui/icons-material/GridOn";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import LeftRightButtons from "@/components/LeftRightButtons";

import { splitUpGames, useUserGames } from "@/hooks/userGamesHook";
import { useUser } from "@/context/useUser";
import { getGamePath, SomeGameData } from "@sportsball/shared";

const GAME_ICONS = {
  ["Squares"]: <GridOnIcon />,
  ["Pickem"]: <CheckBoxIcon />,
} as const;

export default function GameTitle({ game, navButtons }: { game: SomeGameData; navButtons?: true }) {
  const { user } = useUser();
  const uid = user?.uid;
  const games = useUserGames();
  const { hosting, enteredNotHosting } =
    !uid || !games ? { hosting: [], enteredNotHosting: [] } : splitUpGames(games, { uid });

  const navPaths = !navButtons ? undefined : [...hosting, ...enteredNotHosting].map((game) => getGamePath(game));
  const gameIcon = GAME_ICONS[game.type];

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {gameIcon}
      <Typography fontFamily="Nico Moji" variant="h6" flexGrow={1}>
        {game.name}
      </Typography>
      {navPaths && <LeftRightButtons paths={navPaths} />}
    </Stack>
  );
}
