import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "./theme";
import "./main.css";
import App from "./App";
import FirebaseProvider from "./context/FirebaseProvider";
import UserProvider from "./context/UserProvider";
import SpecialsProvider from "./context/SpecialsProvider";
import ScrollToTop from "./context/ScrollToTop";

import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

export function origin() {
  const { hostname } = window.location;
  switch (hostname) {
    case "localhost":
      return "localhost";

    case "sportsball-prod.web.app":
    case "sportsball-prod.firebaseapp.com":
      return "prod";

    default:
      if (hostname.endsWith("joinsportsball.com")) {
        return "prod";
      } else {
        return "stage";
      }
  }
}

// Ensure Sentry is only initialized once
if (!window.__SENTRY_INITIALIZED__) {
  import("@sentry/react")
    .then((Sentry) => {
      // Determine environment based on hostname
      const environment =
        window.location.hostname === "localhost"
          ? "development"
          : window.location.hostname.includes("stage")
          ? "staging"
          : "production";

      console.log(`Initializing Sentry for ${environment} environment`);

      Sentry.init({
        dsn: "https://06ce727a18cbc2e42630f7da8c9c6422@o4508944393306112.ingest.us.sentry.io/4508944395010048",
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        environment: environment,
        tracesSampleRate: environment === "production" ? 0.2 : 1.0, // Lower sampling rate in production
        tracePropagationTargets: [
          "localhost",
          "https://joinsportsball.com",
          "https://sportsball-stage.firebaseapp.com",
        ],
        replaysSessionSampleRate: environment === "production" ? 0.1 : 1.0,
        replaysOnErrorSampleRate: 1.0,
        // Always enabled, but tagged with the correct environment
        enabled: true,

        // Filter out unwanted errors in development
        beforeSend: (event, hint) => {
          // Only apply filtering in development environment
          if (environment === "development") {
            // Example: Filter out specific error types you don't want to track in development
            const error = hint?.originalException;
            let errorMessage = "";

            // Safely extract error message regardless of error type
            if (typeof error === "string") {
              errorMessage = error;
            } else if (error instanceof Error) {
              errorMessage = error.message;
            } else if (error && typeof error === "object" && "message" in error) {
              errorMessage = String(error.message);
            }

            // Filter out React development mode errors and other noise
            const ignoredErrors = [
              "ResizeObserver loop limit exceeded",
              "ResizeObserver loop completed with undelivered notifications",
              "Loading chunk", // Ignore chunk loading errors in development
              "Failed to load resource", // Ignore resource loading errors in development
              "Network Error",
              "[HMR]", // Ignore Hot Module Replacement errors
              "Uncaught SyntaxError", // Often from development mode
            ];

            if (errorMessage && ignoredErrors.some((ignored) => errorMessage.includes(ignored))) {
              console.log(`[Sentry] Filtered out development error: ${errorMessage}`);
              return null; // Don't send this event to Sentry
            }
          }

          return event;
        },
      });

      // Set the global flag to true after initialization
      window.__SENTRY_INITIALIZED__ = true;
    })
    .catch((error) => {
      console.error("Error importing Sentry/react:", error);
    });
}

export function circularProgress() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <CircularProgress size={120} />
    </Box>
  );
}

if (process.env.NODE_ENV !== "test") {
  createRoot(document.getElementById("root")!).render(
    <StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
          <ScrollToTop />
          <SpecialsProvider>
            <FirebaseProvider>
              <UserProvider>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </UserProvider>
            </FirebaseProvider>
          </SpecialsProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StrictMode>
  );
}
