import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";

import { TeamScores } from "@sportsball/shared";

import { ScoreProps } from "@/style";

export enum FootballSquaresPeriod {
  Q1 = "Q1",
  Half = "Half",
  Q3 = "Q3",
  Final = "Final",
}
export const AllFootballSquaresPeriods = Object.values(FootballSquaresPeriod);

export function FootballPeriodScore({
  sport,
  period,
  scores,
  isActive,
}: {
  sport: "Football" | "Basketball";
  period: FootballSquaresPeriod;
  scores: TeamScores | undefined;
  isActive: boolean;
}) {
  return (
    <Grid size={1}>
      <Stack direction="column" alignItems="center">
        <Box width="95%" border={1}>
          <Typography {...ScoreProps} align="center">
            {scores ? `${scores.away} - ${scores.home}` : "-"}
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center">
          {isActive &&
            (sport === "Football" ? (
              <SportsFootballIcon color="error" fontSize="small" />
            ) : (
              <SportsBasketballIcon color="error" fontSize="small" />
            ))}
          <Typography align="center">{period}</Typography>
        </Stack>
      </Stack>
    </Grid>
  );
}
