import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import deepEqual from "deep-equal";

import type { User as FirebaseUser } from "@firebase/auth";

import { LegacyAnonPromotion, UserData } from "@sportsball/shared";

import { useFirebasePreUser } from "./useFirebasePreUser";
import { User, UserContext } from "./useUser";

import { promoteAnonUser } from "@/cloudFunctions";
import { generateTakeoverCode } from "@/cloudFunctions";

export type BaseUser =
  | {
      type: "anonymousFirebaseUser";
      firebaseUid: string;
    }
  | {
      type: "signedInFirebaseUser";
      firebaseUid: string;
      email: string;
    };
export interface TakeoverCode {
  anonUid: string;
  takeoverCode: string;
}

function baseUserFromFirebaseUser(firebaseUser: FirebaseUser | null): BaseUser | undefined {
  if (!firebaseUser) {
    return undefined;
  }
  const { uid, isAnonymous, email } = firebaseUser;
  if (isAnonymous) {
    return { type: "anonymousFirebaseUser", firebaseUid: uid };
  }
  if (!email) {
    throw new Error("email is required for signed in users");
  }
  return { type: "signedInFirebaseUser", firebaseUid: uid, email };
}

export default function UserProvider({ children }: { children: ReactNode }) {
  const firebase = useFirebasePreUser();
  const [baseUser, setBaseUser] = useState<BaseUser | undefined>(
    baseUserFromFirebaseUser(!firebase ? null : firebase.auth.currentUser)
  );
  const [userData, setUserData] = useState<UserData | null | undefined>(null);
  const [anonPromotion, setAnonPromotion] = useState<LegacyAnonPromotion | null | undefined>(null);
  const [selfTakeoverCode, setSelfTakeoverCode] = useState<TakeoverCode | undefined>();
  const [otherTakeoverCode, setOtherTakeoverCode] = useState<(TakeoverCode & { email: string }) | undefined>();

  // Update baseUser when firebase user changes
  useEffect(() => {
    if (!firebase) {
      return;
    }

    const { onAuthStateChanged, signInAnonymously } = firebase.authPackage;
    return onAuthStateChanged(firebase.auth, (firebaseUser) => {
      const newBaseUser = baseUserFromFirebaseUser(firebaseUser);

      // kick off anonymous sign-in for very first time in
      if (!newBaseUser) {
        signInAnonymously(firebase.auth).catch(console.error);
      }

      // if there's no change to base user then we can stop right here
      if (deepEqual(newBaseUser, baseUser)) {
        return;
      }

      console.info("onAuthStateChanged updating baseUser and resetting user state", newBaseUser);
      setBaseUser(newBaseUser);
      setUserData(null);
      setAnonPromotion(null);

      if (newBaseUser?.type === "signedInFirebaseUser") {
        // when we have a real signed in user we know we won't have a promotion
        setAnonPromotion(undefined);
      }
    });
  }, [firebase, baseUser]);

  // Watch promotion status for anonymous users
  useEffect(() => {
    if (!baseUser || !firebase || baseUser.type !== "anonymousFirebaseUser") {
      return;
    }

    const { firebaseUid } = baseUser;
    const { collection, doc, onSnapshot } = firebase.firestorePackage;
    console.info("watching anonPromotion doc", firebaseUid);
    const anonPromotionDoc = doc(collection(firebase.firestore, "anon-promotions"), firebaseUid);

    return onSnapshot(anonPromotionDoc, (snapshot) => {
      if (snapshot.exists()) {
        const anonPromotion = LegacyAnonPromotion.parse(snapshot.data());
        console.info("found anon promotion - user is signed in as", anonPromotion.promotedToUid, anonPromotion.email);
        setAnonPromotion(anonPromotion);
        // expect new user prefs to be loaded
        setUserData(null);
      } else {
        console.info("no anon promotion - user is signed out", firebaseUid);
        setAnonPromotion(undefined);
        // we now know we don't have user prefs
        setUserData(undefined);

        // Generate takeover code for signed out users
        console.info("generating self takeover code", baseUser.firebaseUid);
        generateTakeoverCode(firebase)
          .then(({ takeoverCode }) => setSelfTakeoverCode({ takeoverCode, anonUid: baseUser.firebaseUid }))
          .catch(console.error);
      }
    });
  }, [baseUser, firebase]);

  // userPrefsUid is the uid of the user id that is used to load user prefs
  let userDataUid: string | undefined;
  if (baseUser) {
    if (baseUser.type === "signedInFirebaseUser") {
      userDataUid = baseUser.firebaseUid;
    } else if (anonPromotion !== null) {
      userDataUid = anonPromotion?.promotedToUid;
    }
  }

  // Update userData when userDataUid changes
  useEffect(() => {
    if (!firebase || !userDataUid) {
      return;
    }

    // we have a uid - monitor userData
    const { collection, doc, onSnapshot } = firebase.firestorePackage;
    const usersCollection = collection(firebase.firestore, "users");
    const userDataDoc = doc(usersCollection, userDataUid);

    return onSnapshot(userDataDoc, (snapshot) => {
      if (snapshot.exists()) {
        const userData = UserData.parse(snapshot.data());
        console.info("onSnapshot updating userData", userData);
        setUserData(userData);
      } else {
        console.info("onSnapshot userData not found");
        setUserData(undefined);
      }
    });
  }, [firebase, userDataUid]);

  // handle webview pages with incoming custom tokens
  useEffect(() => {
    if (!firebase) {
      return;
    }

    const url = window.location.pathname;
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (url.startsWith("/webview") && token) {
      const { signInWithCustomToken } = firebase.authPackage;
      console.log("Signing in with token", token);
      // Sign in with the token received from mobile app
      signInWithCustomToken(firebase.auth, token).catch(alert);
    }
  }, [firebase]);

  // three phases here 1) true anonymous user (anon firebase user with known no promotion)
  // 2) signed in user with no user data yet
  // 3) signed in user with user data loaded
  const user = useMemo((): User | undefined => {
    if (baseUser && anonPromotion !== null && userData !== null) {
      if (baseUser.type === "anonymousFirebaseUser" && !anonPromotion) {
        return { type: "anonymous" as const, uid: baseUser.firebaseUid };
      }
      const uid = anonPromotion ? anonPromotion.promotedToUid : baseUser.firebaseUid;
      if (!userData) {
        const email = baseUser.type === "signedInFirebaseUser" ? baseUser.email : anonPromotion?.email;
        if (!email) {
          throw new Error(
            "email is required for signed in users. Not expecting to use older anon-promotion with missing email"
          );
        }
        return { type: "signedInNoData" as const, uid, email };
      } else {
        return { type: "signedIn" as const, uid, ...userData };
      }
    }
  }, [baseUser, anonPromotion, userData]);

  // signOut: if promoted then we drop the promotion otherwise we just sign in anonymously
  const signOut = useCallback(async () => {
    if (!firebase || !baseUser) {
      return;
    }
    console.log("signOut", baseUser, anonPromotion);
    if (anonPromotion) {
      const { collection, doc, deleteDoc } = firebase.firestorePackage;
      console.info("deleting promotion");
      await deleteDoc(doc(collection(firebase.firestore, "anon-promotions"), baseUser.firebaseUid));
    } else {
      console.info("signing in anonymously");
      await firebase.authPackage.signInAnonymously(firebase.auth);
    }
  }, [firebase, baseUser, anonPromotion]);

  // once we have a firebase signin we can promote any anonymous user if awe have any takeover codes
  useEffect(() => {
    if (!firebase || !baseUser || baseUser.type !== "signedInFirebaseUser") {
      return;
    }
    if (!selfTakeoverCode && !otherTakeoverCode) {
      return;
    }

    // async processing of takeover codes after useEffect completes
    void (async () => {
      try {
        if (selfTakeoverCode) {
          console.info("promoting self promotion", selfTakeoverCode.anonUid);
          await promoteAnonUser(firebase, { ...selfTakeoverCode, email: baseUser.email, andDelete: true });
          setSelfTakeoverCode(undefined);
        }
        if (otherTakeoverCode) {
          if (otherTakeoverCode.email === baseUser.email) {
            console.info("promoting expected takeover email", otherTakeoverCode.email, otherTakeoverCode.anonUid);
            await promoteAnonUser(firebase, { ...otherTakeoverCode, andDelete: false });
            setOtherTakeoverCode(undefined);
          } else {
            console.info(
              `other takeover code email ${otherTakeoverCode.email} does not match user email ${baseUser.email} so not taking over`
            );
          }
        }
      } catch (error) {
        console.error("Error during promotion:", error);
      }
    })();
  }, [firebase, baseUser, selfTakeoverCode, otherTakeoverCode]);

  return (
    <UserContext.Provider
      value={
        firebase && user
          ? { firebase, user, setOtherTakeoverCode, signOut }
          : { firebase: undefined, user: undefined, setOtherTakeoverCode, signOut }
      }
    >
      {children}
    </UserContext.Provider>
  );
}
