import { SquaresGameData } from "@sportsball/shared";
import DemoSquaresGame from "./DemoSquaresGame";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import { useState } from "react";

export default function SuperBowl2025Demo({ onClose }: { onClose?: () => void }) {
  const [isPlaying, setIsPlaying] = useState(true);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const newGameData: SquaresGameData = {
    type: "Squares",
    uid: "123",
    id: "123",
    size: 25,
    name: "Super Bowl 2025",
    description: "Super Bowl 2025",
    code: "SB2025",
    startTimestamp: 1739143800000,
    changeTargetScores: "SameAllGame",
    sportsGames: {
      type: "Football",
      games: {
        13466: {
          type: "Football",
          id: "13466",
          date: "2025-02-07",
          week: "Super Bowl",
          league: "NFL",
          stage: "Post Season",
          status: "NS",
          startTimestamp: 1739143800000,
          teams: {
            away: {
              name: "Eagles",
              city: "Philadelphia",
              shortName: "PHI",
              record: "14-3",
            },
            home: {
              name: "Chiefs",
              city: "Kansas City",
              shortName: "KC",
              record: "15-2",
            },
          },
        },
      },
    },
    userRefs: ["123"],
    users: {
      "123": {
        handle: "John Doe",
        avatar: {
          set: "avatars-1",
          id: 1,
        },
      },
    },
  };

  return (
    <Box position="relative">
      {onClose && (
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 10,
            bgcolor: "rgba(255, 255, 255, 0.7)",
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.9)",
            },
          }}
          aria-label="Close demo"
        >
          <CloseIcon />
        </IconButton>
      )}
      <Box onClick={togglePlayPause} sx={{ cursor: "pointer" }}>
        <DemoSquaresGame gameData={newGameData} isPlaying={isPlaying} />
      </Box>
    </Box>
  );
}
