import type { FirebaseApp } from "@firebase/app";
import type { Analytics } from "@firebase/analytics";
import type { Auth } from "@firebase/auth";
import type { Database } from "@firebase/database";
import type { Firestore } from "@firebase/firestore";
import type { Functions } from "@firebase/functions";
import { createContext, useContext } from "react";

export type LazyFirebase = {
  app: FirebaseApp;
  analytics: Analytics;
  auth: Auth;
  database: Database;
  firestore: Firestore;
  functions: Functions;

  appPackage: typeof import("@firebase/app");
  analyticsPackage: typeof import("@firebase/analytics");
  authPackage: typeof import("@firebase/auth");
  databasePackage: typeof import("@firebase/database");
  firestorePackage: typeof import("@firebase/firestore");
  functionsPackage: typeof import("@firebase/functions");
};

export const FirebaseContext = createContext<LazyFirebase | undefined>(undefined);

export function useFirebasePreUser() {
  return useContext(FirebaseContext);
}
