import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
  getTotalScore,
  isSportsGameActive,
  PeriodResult,
  singleSportsGame,
  SomeSportsGame,
  SquaresGameData,
  SquaresGameStatus,
} from "@sportsball/shared";

import { AllFootballSquaresPeriods, FootballPeriodScore } from "./FootballPeriodScore";
import { LightGridGuideColor } from "./SquaresBoardColors";
import { ScoreProps } from "@/style";

function statusScoreboardLabel(sportsGame: SomeSportsGame) {
  switch (sportsGame.type) {
    case "Basketball":
      switch (sportsGame.status) {
        case "NS":
          return "Warmup";
        case "HT":
          return "Half";
        case "BT":
          return ["Q1", "Q2", "Q3", "Q4"][sportsGame.periodScores?.length ?? 0];
        case "FT":
          return "Final";
      }
  }
  return sportsGame.status;
}

export default function SquaresScoreboard({
  game,
  gameStatus,
}: {
  game: SquaresGameData;
  gameStatus?: SquaresGameStatus;
}) {
  const sportsGame = singleSportsGame(game.sportsGames);
  const periodResults: (PeriodResult | undefined)[] = [...(gameStatus?.periodResults ?? [])];
  const activePeriodIndex = isSportsGameActive(sportsGame) ? periodResults.length - 1 : undefined;

  const fullGameSize = sportsGame.type === "Baseball" ? 20 : 4;
  while (periodResults.length < fullGameSize) {
    periodResults.push(undefined);
  }

  const totalScore = getTotalScore(sportsGame) ?? { away: 0, home: 0 };
  switch (sportsGame.type) {
    case "Football":
    case "Basketball":
      return (
        <>
          <Stack direction="row" justifyContent="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
                p: 2,
                border: 1,
                borderColor: "divider",
                borderRadius: 1,
              }}
            >
              {/* Away Score */}
              <Box sx={{ textAlign: "center", width: "120px" }}>
                <Typography variant="h4" fontWeight="bold">
                  {sportsGame.teams.away.name}
                </Typography>
                <Typography variant="h4" fontWeight="bold" sx={{ ...ScoreProps, transition: "all 450ms ease-in-out" }}>
                  {totalScore.away}
                </Typography>
              </Box>

              {/* Time and Period */}
              <Box sx={{ textAlign: "center", width: "100px" }}>
                <Typography variant="h5" fontWeight="bold" sx={{ ...ScoreProps, transition: "all 450ms ease-in-out" }}>
                  {sportsGame.timer ?? "0:00"}
                </Typography>
                <Typography variant="body1">{statusScoreboardLabel(sportsGame)}</Typography>
              </Box>

              {/* Home Score */}
              <Box sx={{ textAlign: "center", width: "120px" }}>
                <Typography variant="h4" fontWeight="bold">
                  {sportsGame.teams.home.name}
                </Typography>
                <Typography variant="h4" fontWeight="bold" sx={{ ...ScoreProps, transition: "all 450ms ease-in-out" }}>
                  {totalScore.home}
                </Typography>
              </Box>
            </Box>
          </Stack>

          <Box padding={2}>
            <Grid container columns={4} width="100%">
              {periodResults.map((periodResult, i) => (
                <FootballPeriodScore
                  key={i}
                  sport={sportsGame.type}
                  period={AllFootballSquaresPeriods[i]}
                  scores={periodResult?.scores}
                  isActive={i === activePeriodIndex}
                />
              ))}
            </Grid>
          </Box>
        </>
      );
    case "Baseball": {
      const getCellStyle = (periodIndex?: number) => ({
        sx: {
          border: 1,
          borderColor: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 1,
          whiteSpace: "nowrap",
          bgcolor: periodIndex !== undefined && periodIndex === activePeriodIndex ? LightGridGuideColor : "inherit",
          transition: "background-color 450ms ease-in-out",
        },
      });

      const totalScore = getTotalScore(sportsGame);
      return (
        <>
          <Grid container columns={13}>
            {/* Header Row */}
            <Grid size={3}></Grid>
            {Array.from({ length: 10 }, (_, i) => (
              <Grid key={i} size={1} {...getCellStyle()}>
                <Typography fontWeight="bold" fontSize="0.8em">
                  {i < 9 ? i + 1 : "10+"}
                </Typography>
              </Grid>
            ))}

            {/* Away Team Row */}
            <Grid size={3} {...getCellStyle()}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography fontWeight="bold">{sportsGame.teams.away.shortName}</Typography>
                {totalScore && (
                  <Typography fontWeight="bold" sx={{ ml: 1, transition: "all 450ms ease-in-out" }}>
                    {totalScore.away}
                  </Typography>
                )}
              </Box>
            </Grid>
            {Array.from({ length: 10 }, (_, i) => (
              <Grid key={i} size={1} {...getCellStyle(i * 2)}>
                <Typography fontSize="0.8em" sx={{ transition: "all 450ms ease-in-out" }}>
                  {sportsGame.periodScores?.[i * 2] ?? "-"}
                </Typography>
              </Grid>
            ))}

            {/* Home Team Row */}
            <Grid size={3} {...getCellStyle()}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography fontWeight="bold">{sportsGame.teams.home.shortName}</Typography>
                {totalScore && (
                  <Typography fontWeight="bold" sx={{ ml: 1, transition: "all 450ms ease-in-out" }}>
                    {totalScore.home}
                  </Typography>
                )}
              </Box>
            </Grid>
            {Array.from({ length: 10 }, (_, i) => (
              <Grid key={i} size={1} {...getCellStyle(i * 2 + 1)}>
                <Typography fontSize="0.8em" sx={{ transition: "all 450ms ease-in-out" }}>
                  {sportsGame.periodScores?.[i * 2 + 1] ?? "-"}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </>
      );
    }
  }
}
