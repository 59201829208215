import { LazyFirebase } from "@/context/useFirebasePreUser";
import { User } from "@/context/useUser";

export const AnalyticsEvents = {
  // Page Views
  VIEW_HOME: "view_home",
  VIEW_GAME: "view_game",
  VIEW_PROFILE: "view_profile",
  VIEW_LEADERBOARD: "view_leaderboard",
  VIEW_DURATION: "view_duration",

  // Home Page
  HERO_CTA_CLICK: "hero_cta_click",
  GAME_CODE_SUBMIT: "game_code_submit",
  SEARCH_GAME: "search_game",

  // Game Creation & Setup
  CREATE_GAME: "create_game",
  JOIN_GAME: "join_game",
  SHARE_GAME: "share_game",
  DELETE_GAME: "delete_game",

  // Squares Game Events
  SELECT_SQUARE: "select_square",
  PURCHASE_SQUARE: "purchase_square",
  VIEW_SQUARES_BOARD: "view_squares_board",
  LOCK_SQUARES_GAME: "lock_squares_game",
  CONFIGURE_SQUARES_GAME: "configure_squares_game",
  SELECT_SPORTS_GAME: "select_sports_game",
  CHANGE_TEAM_SLATE: "change_team_slate",
  OPEN_SQUARES_SETTINGS: "open_squares_settings",

  // Pickem Game Events
  SUBMIT_PICKS: "submit_picks",
  CHANGE_PICK: "change_pick",
  LOCK_PICKEM_GAME: "lock_pickem_game",

  // Authentication
  SIGN_IN: "sign_in",
  SIGN_OUT: "sign_out",
  SIGN_UP: "sign_up",

  // Open Dialogs
  OPEN_SIGN_IN_DIALOG: "open_sign_in_dialog",

  // Error Events
  ERROR_OCCURRED: "error_occurred",
  VALIDATION_ERROR: "validation_error",

  // Game Status
  GAME_COMPLETE: "game_complete",
  GAME_STARTED: "game_started",

  // Demo Game Events
  DEMO_SQUARES_GAME_VIEW: "demo_squares_game_view",
  DEMO_SQUARES_GAME_PLAYBACK: "demo_squares_game_playback",
} as const;

export type AnalyticsEventName = (typeof AnalyticsEvents)[keyof typeof AnalyticsEvents];

// Common parameter types for better consistency
export interface GameAnalyticsParams {
  game_id: string;
  game_type: "squares" | "pickem";
  sport_type?: string;
}

export interface ErrorAnalyticsParams {
  error_type: string;
  error_message: string;
  page?: string;
  action?: string;
}

// Add new interface for demo analytics params
export interface DemoAnalyticsParams {
  sport: string;
  size?: number;
  action?: "play" | "pause";
  current_frame?: number;
}

export function logAnalyticsEvent(
  firebase: LazyFirebase | undefined,
  user: User | undefined,
  eventName: AnalyticsEventName,
  params: Record<string, unknown>
) {
  if (!firebase || !user || (user.type === "signedIn" && user.analyticsOptOut)) {
    return;
  }

  import("isbot")
    .then(({ isbot }) => {
      if (!isbot(navigator.userAgent)) {
        const { logEvent } = firebase.analyticsPackage;
        logEvent(firebase.analytics, eventName as string, params);
      }
    })
    .catch((err) => {
      console.error("Error logging analytics event", err);
    });
}
