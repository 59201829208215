import { useState } from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
  CurrentOdds,
  FootballGame,
  ScheduledGame,
  PickemQuestion,
  ScheduledFootballGame,
  CreatePickemGameParameters,
} from "@sportsball/shared";
import { TeamSlate } from "@sportsball/shared";

import PickemSheet from "@/components/PickemSheet";
import SlateGamesPicker from "@/components/SlateGamesPicker";
import FullSizeCard from "@/components/FullSizeCard";

import { useLoggedInDatabaseObject } from "@/hooks/loggedInObjectHook";
import { calculateMoneyLineScoring } from "@/data/pickemScoring";

type PickemGameType = PickemQuestion["type"];
export type NewPickemGameData = Omit<CreatePickemGameParameters, "status" | "name" | "description">;

export default function NewPickemGame({
  setNewGameData,
}: {
  setNewGameData: (newGameData: NewPickemGameData | undefined) => void;
}) {
  const { teamSlate: teamSlateParam } = useParams() as { teamSlate?: TeamSlate };
  const [teamSlate, setTeamSlate] = useState(teamSlateParam ?? TeamSlate.NFL);
  const [pickemGameType, setPickemGameType] = useState<PickemGameType>("VsSpreadPick");
  const [noTiebreaker, setNoTiebreaker] = useState(false);
  const [podiumSize, setPodiumSize] = useState(1);
  const [slateWeekGames, setSlateWeekGames] = useState<ScheduledFootballGame[]>([]);
  const [removedFootballGames, setRemovedFootballGames] = useState(new Set<string>());

  const { object: currentOdds } = useLoggedInDatabaseObject("odds/current", CurrentOdds);

  let workingGameData: NewPickemGameData | undefined;

  function buildPickemQuestion(scheduledGame: ScheduledGame): PickemQuestion {
    const footballGameId = scheduledGame.id;
    const { vegasOdds, spread } = currentOdds?.[footballGameId] ?? {};
    if (pickemGameType === "StraightUpPick" || !vegasOdds) {
      return {
        type: "StraightUpPick",
        footballGameId,
      };
    }
    if (pickemGameType === "VsSpreadPick") {
      return {
        type: "VsSpreadPick",
        footballGameId,
        vegasOdds,
        vsSpread: spread ?? "OFF",
      };
    }
    return {
      type: "MoneyLinePick",
      footballGameId,
      vegasOdds,
      moneyLinePoints: calculateMoneyLineScoring(vegasOdds),
    };
  }

  function buildGameData(weekGames: ScheduledFootballGame[]): NewPickemGameData {
    const questions = weekGames.map((scheduledGame) => buildPickemQuestion(scheduledGame));
    // add status to all the scheduled games to make startup FootballGames from ScheduledGames
    const sportsGames: Record<string, FootballGame> = Object.fromEntries(
      weekGames.map((scheduledGame) => [scheduledGame.id, { ...scheduledGame, status: "NS" }])
    );
    const tiebreakerId = noTiebreaker ? undefined : questions[questions.length - 1].footballGameId;

    return {
      type: "Pickem",
      questions,
      ...(tiebreakerId && { tiebreakerId }),
      sportsGames: { type: "Football", games: sportsGames },
    };
  }

  if (slateWeekGames && slateWeekGames.length > 0 && currentOdds) {
    // workingGameData is the full slate but the newGameData, which is the data that is
    // used to create the game, is the data with the football games removed that the user
    // has selected to remove
    workingGameData = buildGameData(slateWeekGames);
    const postRemovalWeekGames = slateWeekGames.filter((game) => !removedFootballGames.has(game.id));
    setNewGameData(buildGameData(postRemovalWeekGames));
  } else {
    setNewGameData(undefined);
  }

  function toggleFootballGames(footballGameIds: string[]) {
    if (footballGameIds.length === 0) {
      return;
    }
    const newRemovedFootballGames = new Set(removedFootballGames);
    for (const footballGameId of footballGameIds) {
      if (newRemovedFootballGames.has(footballGameId)) {
        newRemovedFootballGames.delete(footballGameId);
      } else {
        newRemovedFootballGames.add(footballGameId);
      }
    }
    setRemovedFootballGames(newRemovedFootballGames);
  }

  function setTeamSlateFn(teamSlate: TeamSlate) {
    setTeamSlate(teamSlate);
    window.history.replaceState(null, "", `/games/new/football-pickem/slate/${teamSlate}`);
  }

  return (
    <Stack spacing={4}>
      {/* Game Selection */}
      <FullSizeCard>
        <Stack spacing={3}>
          <SlateGamesPicker
            title="Choose Pick'em Pool Slate"
            description="Pick'em slates provide the list of games and spreads all NFL and NCAA matchups. These spreads do not change once the pick'em slate is chosen for the game."
            teamSlate={teamSlate}
            setTeamSlate={setTeamSlateFn}
            slateWeekGames={slateWeekGames}
            setSlateWeekGames={setSlateWeekGames}
          />
        </Stack>
      </FullSizeCard>

      {/* Game Type Selection */}
      <FullSizeCard>
        <Stack spacing={4}>
          <Box>
            <Typography variant="h5" component="h2" gutterBottom>
              Choose the pick&apos;em game type
            </Typography>
            <Typography gutterBottom>
              For easy predictions, choose straight-up winners. For a more challenging game, choose wins against the
              spread.
            </Typography>
            <Box display="flex" justifyContent="center" mt={2}>
              <ButtonGroup variant="contained" size="large">
                <Button
                  variant={pickemGameType === "MoneyLinePick" ? "contained" : "outlined"}
                  onClick={() => setPickemGameType("MoneyLinePick")}
                  sx={{ px: 3, py: 1 }}
                >
                  Money Line
                </Button>
                <Button
                  variant={pickemGameType === "VsSpreadPick" ? "contained" : "outlined"}
                  onClick={() => setPickemGameType("VsSpreadPick")}
                  sx={{ px: 3, py: 1 }}
                >
                  Against the Spread
                </Button>
                <Button
                  variant={pickemGameType === "StraightUpPick" ? "contained" : "outlined"}
                  onClick={() => setPickemGameType("StraightUpPick")}
                  sx={{ px: 3, py: 1 }}
                >
                  Straight-Up
                </Button>
              </ButtonGroup>
            </Box>
          </Box>

          <Box>
            <Typography variant="h6" gutterBottom>
              Tiebreaker Options
            </Typography>
            <Typography gutterBottom>Would you like to include an over/under tiebreaker questions?</Typography>
            <Box display="flex" justifyContent="center" mt={2}>
              <ButtonGroup variant="contained" size="large">
                <Button
                  variant={!noTiebreaker ? "contained" : "outlined"}
                  onClick={() => setNoTiebreaker(false)}
                  sx={{ px: 3, py: 1 }}
                >
                  Include Tiebreaker
                </Button>
                <Button
                  variant={noTiebreaker ? "contained" : "outlined"}
                  onClick={() => setNoTiebreaker(true)}
                  sx={{ px: 3, py: 1 }}
                >
                  No Tiebreaker
                </Button>
              </ButtonGroup>
            </Box>
          </Box>

          <Box>
            <Typography variant="h6" gutterBottom>
              Winner&apos;s Podium Size
            </Typography>
            <Box display="flex" justifyContent="center">
              <ButtonGroup variant="contained" size="large">
                <Button
                  variant={podiumSize === 1 ? "contained" : "outlined"}
                  onClick={() => setPodiumSize(1)}
                  sx={{ px: 2, py: 1 }}
                >
                  1st
                </Button>
                <Button
                  variant={podiumSize === 2 ? "contained" : "outlined"}
                  onClick={() => setPodiumSize(2)}
                  sx={{ px: 2, py: 1 }}
                >
                  Top 2
                </Button>
                <Button
                  variant={podiumSize === 3 ? "contained" : "outlined"}
                  onClick={() => setPodiumSize(3)}
                  sx={{ px: 2, py: 1 }}
                >
                  Top 3
                </Button>
                <Button
                  variant={podiumSize === 5 ? "contained" : "outlined"}
                  onClick={() => setPodiumSize(5)}
                  sx={{ px: 2, py: 1 }}
                >
                  Top 5
                </Button>
                <Button
                  variant={podiumSize === 10 ? "contained" : "outlined"}
                  onClick={() => setPodiumSize(10)}
                  sx={{ px: 2, py: 1 }}
                >
                  Top 10
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Stack>
      </FullSizeCard>

      {/* Game Slate */}
      <FullSizeCard>
        <Stack spacing={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Game Slate
          </Typography>
          <Typography>Customize your game by adding/removing individual games from the slate.</Typography>
          {workingGameData && (
            <Box mt={2}>
              <PickemSheet
                game={workingGameData}
                removedFootballGames={removedFootballGames}
                toggleFootballGames={toggleFootballGames}
              />
            </Box>
          )}
        </Stack>
      </FullSizeCard>
    </Stack>
  );
}
