import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import TagManager from "react-gtm-module";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Footer from "@/components/Footer";
import GameContainer from "@/components/GameContainer";
import Titlebar from "@/components/Titlebar";
import UserPrefsDialog from "@/components/UserPrefsDialog";

import { AnalyticsEvents, logAnalyticsEvent } from "@/lib/analytics";
import FinishEmailSignIn from "@/pages/FinishEmailSignIn";
import Game from "@/pages/Game";
import Home from "@/pages/Home";
import JoinPickemGame from "@/pages/JoinPickemGame";
import NewGame from "@/pages/NewGame";
import Room from "@/pages/Room";
import ShowEntry from "@/pages/ShowEntry";
import WebViewGame from "@/pages/WebViewGame";
import { useUser } from "./context/useUser";
import { GamesProvider } from "./hooks/userGamesHook";

// Component to manage canonical tag
function CanonicalTag() {
  const location = useLocation();
  const [canonicalUrl, setCanonicalUrl] = useState<string | undefined>();

  useEffect(() => {
    const url = `${window.location.origin}${location.pathname}`;
    setCanonicalUrl(url);
  }, [location.pathname]);

  return canonicalUrl ? (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  ) : null;
}

export default function App() {
  const { user, firebase } = useUser();
  const location = useLocation();
  const [showUserPrefs, setShowUserPrefs] = useState(false);

  const isInWebView = window.location.pathname.startsWith("/webview");

  // Track page views
  useEffect(() => {
    if (!firebase) return;

    logAnalyticsEvent(firebase, user, AnalyticsEvents.VIEW_HOME, {
      page_path: location.pathname,
      page_location: window.location.href,
      in_webview: isInWebView,
      user_type: user?.type,
    });
  }, [location, firebase, user, isInWebView, user?.type]);

  useEffect(() => {
    if (user?.type === "signedInNoData") {
      setShowUserPrefs(true);
      logAnalyticsEvent(firebase, user, AnalyticsEvents.SIGN_UP, { user_id: user.uid });

      TagManager.initialize({
        gtmId: "AW-16847332129",
        events: {
          conversion: { send_to: ["AW-16847332129/ITN_COuX3pcaEKHGt-E-"] },
        },
      });
    } else {
      TagManager.initialize({ gtmId: "AW-16847332129" });
    }
  }, [user, firebase]);

  return (
    <HelmetProvider>
      <CanonicalTag />
      <GamesProvider>
        {!isInWebView && <Titlebar />}
        <Box component="main" width="100%" sx={{ userSelect: "none" }}>
          <Box marginBottom={1}>
            {!isInWebView && <Toolbar />}

            <Routes>
              <Route index element={<Home />} />

              <Route path="/games/new/football-pickem" element={<NewGame gameType="Pickem" sport="Football" />} />
              <Route
                path="/games/new/football-pickem/slate/:teamSlate"
                element={<NewGame gameType="Pickem" sport="Football" />}
              />

              <Route path="/games/new/football-squares" element={<NewGame gameType="Squares" sport="Football" />} />
              <Route
                path="/games/new/football-squares/special/:specialId"
                element={<NewGame gameType="Squares" sport="Football" />}
              />
              <Route
                path="/games/new/football-squares/slate/:teamSlate"
                element={<NewGame gameType="Squares" sport="Football" />}
              />

              <Route
                path="/games/new/baseball-squares"
                element={<NewGame key="baseball-squares" gameType="Squares" sport="Baseball" />}
              />
              <Route
                path="/games/new/basketball-squares"
                element={<NewGame key="basketball-squares" gameType="Squares" sport="Basketball" />}
              />
              <Route
                path="/games/new/football-squares"
                element={<NewGame key="football-squares" gameType="Squares" sport="Football" />}
              />

              <Route path="/rooms/:roomId" element={<Room />} />

              <Route path="/webview/games/:gameId" element={<GameContainer key={location.pathname} />}>
                <Route index element={<WebViewGame />} />
              </Route>

              <Route path="/webview/new/baseball-squares" element={<NewGame gameType="Squares" sport="Baseball" />} />
              <Route
                path="/webview/new/basketball-squares"
                element={<NewGame gameType="Squares" sport="Basketball" />}
              />
              <Route path="/webview/new/football-squares" element={<NewGame gameType="Squares" sport="Football" />} />
              <Route path="/webview/new/football-pickem" element={<NewGame gameType="Pickem" sport="Football" />} />

              <Route path="/games/:gameId/*" element={<GameContainer key={location.pathname} />}>
                <Route index element={<Game />} />
                <Route path="entries/:entryId" element={<ShowEntry />} />
                <Route path="join" element={<JoinPickemGame />} />
              </Route>

              <Route path="/finish-email-sign-in" element={<FinishEmailSignIn />} />

              <Route path="*" element={<Home />} />
            </Routes>

            <UserPrefsDialog open={showUserPrefs} closeDialog={() => setShowUserPrefs(false)} />
          </Box>
          {!isInWebView && <Footer />}
        </Box>
      </GamesProvider>
    </HelmetProvider>
  );
}
