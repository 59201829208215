import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography, { TypographyProps } from "@mui/material/Typography";

import { LightGridGuideColor } from "./SquaresBoardColors";
import { isGamePeriodActive, SomeSportsGame } from "@sportsball/shared";

function ScoreTypography({
  digits,
  isMatch,
  ...typographyProps
}: {
  digits: (number | undefined)[];
  isMatch: boolean;
} & TypographyProps) {
  return (
    <Typography
      {...typographyProps}
      fontWeight={isMatch ? "900" : undefined}
      sx={{
        ...typographyProps.sx,
        display: "flex",
        alignItems: "center",
      }}
    >
      {digits.length === 1 ? (
        digits[0] ?? "?"
      ) : (
        <>
          {digits[0] ?? "?"}
          <span style={{ fontSize: "0.5em", margin: "0 0.5em", display: "inline-flex", alignItems: "center" }}>or</span>
          {digits[1] ?? "?"}
        </>
      )}
    </Typography>
  );
}

export default function TargetScore({
  targetDigits,
  score,
  visibility,
  sportsGame,
  isAway = false,
  animationPhase = 0,
}: {
  targetDigits: (number | undefined)[];
  score: number | undefined;
  visibility?: "visible" | "hidden";
  sportsGame: SomeSportsGame;
  isAway?: boolean;
  animationPhase?: number;
}) {
  const isMatch = isGamePeriodActive(sportsGame) && score !== undefined && targetDigits.includes(score % 10);
  const bgcolor = isMatch ? LightGridGuideColor : undefined;

  return (
    <Grid
      size={1}
      paddingY={1}
      textAlign="center"
      borderBottom={2}
      borderRight={2}
      bgcolor={bgcolor}
      position="relative"
      sx={{ overflow: "hidden" }}
    >
      {/* Invisible placeholder for layout */}
      <ScoreTypography digits={targetDigits} isMatch={isMatch} visibility={visibility} sx={{ opacity: 0 }} />

      {/* Combined Stack for both current and new score */}
      <Stack
        direction="row"
        justifyContent="center"
        visibility={visibility}
        spacing={0.5}
        alignItems="center"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          padding: 1,

          // Conditional styling based on animation phase
          ...(animationPhase === 0 && {
            // Initial state (current score)
            transform: "none",
            opacity: 1,
          }),

          ...(animationPhase === 1 && {
            // Moving out (current score)
            transform: isAway ? "translateY(50%)" : "translateX(50%)",
            opacity: 0,
            transition: "transform 150ms ease-in-out, opacity 150ms ease-in-out",
          }),

          ...(animationPhase === 2 && {
            // Moving in (new score)
            animation: isAway
              ? "moveInFromTop 225ms ease-in-out forwards"
              : "moveInFromLeft 225ms ease-in-out forwards",
            "@keyframes moveInFromTop": {
              from: { transform: "translateY(-100%)" },
              to: { transform: "translateY(0)" },
            },
            "@keyframes moveInFromLeft": {
              from: { transform: "translateX(-100%)" },
              to: { transform: "translateX(0)" },
            },
          }),
        }}
      >
        <ScoreTypography digits={targetDigits} isMatch={isMatch} visibility={visibility} />
      </Stack>
    </Grid>
  );
}
