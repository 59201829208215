import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "@/context/useUser";

import deepEqual from "deep-equal";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SettingsIcon from "@mui/icons-material/Settings";

import {
  ChangeTargetScores,
  CreateSquaresGameParameters,
  RoomData,
  ScheduledFootballGame,
  ScheduledGame,
  Sport,
  TeamSlate,
} from "@sportsball/shared";

import SlateGamesPicker from "@/components/SlateGamesPicker";

import DayGamePicker from "./DayGamePicker";
import DemoSquaresGame from "./DemoSquaresGame";
import SquaresConfigMenu from "./SquaresConfigDialog";
import { AnalyticsEvents, logAnalyticsEvent } from "@/lib/analytics";

const _periodPayoutSplits = [
  { name: "Big Half & Final", payouts: [3, 9, 3, 10] },
  { name: "Big Final", payouts: [3, 4, 3, 15] },
  { name: "Even", payouts: [6, 6, 6, 7] },
];

export type NewSquaresGameData = Omit<CreateSquaresGameParameters, "status" | "name" | "description">;

function _makeGameData(
  sportsGame: ScheduledGame,
  size: 25 | 100,
  changeTargetScores: ChangeTargetScores
): NewSquaresGameData {
  switch (sportsGame.type) {
    case "Football":
      return {
        type: "Squares",
        size,
        changeTargetScores,
        sportsGames: {
          type: "Football",
          games: {
            [sportsGame.id]: {
              ...sportsGame,
              status: "NS",
            },
          },
        },
      };
    case "Baseball":
      return {
        type: "Squares",
        size,
        changeTargetScores,
        sportsGames: {
          type: "Baseball",
          games: {
            [sportsGame.id]: {
              ...sportsGame,
              status: "NS",
            },
          },
        },
      };
    case "Basketball":
      return {
        type: "Squares",
        size,
        changeTargetScores,
        sportsGames: {
          type: "Basketball",
          games: {
            [sportsGame.id]: {
              ...sportsGame,
              status: "NS",
            },
          },
        },
      };
  }
}

export default function NewSquaresGame({
  sport,
  room,
  newGameData,
  setNewGameData,
}: {
  sport: Sport;
  room: RoomData | undefined;
  newGameData: NewSquaresGameData | undefined;
  setNewGameData: (newGameData: NewSquaresGameData | undefined) => void;
}) {
  const { firebase, user } = useUser();
  const { teamSlate: teamSlateParam } = useParams() as { teamSlate?: TeamSlate };

  if (teamSlateParam && !Object.values(TeamSlate).includes(teamSlateParam)) {
    throw new Error(`Invalid team slate: ${teamSlateParam}`);
  }

  const [teamSlate, setTeamSlate] = useState(teamSlateParam ?? TeamSlate.NFL);
  const [slateWeekGames, setSlateWeekGames] = useState<ScheduledFootballGame[] | undefined>();
  const [selectedGame, setSelectedGame] = useState<ScheduledGame | undefined>();
  const [size, setSize] = useState<25 | 100>(25);
  const [changeTargetScores, setChangeTargetScores] = useState<ChangeTargetScores>(
    sport === "Baseball" ? "ChangeEveryPeriod" : "SameAllGame"
  );
  const [periodPayoutsKey, setPeriodPayoutsKey] = useState<string>(_periodPayoutSplits[0].name);
  const [isPlaying, setIsPlaying] = useState(true);

  // change of pickedTeamSlate can invalidate selectedGame
  useEffect(() => {
    if (sport !== "Football") {
      return;
    }
    // see if we need to reset the selectedGame
    if (!slateWeekGames) {
      if (selectedGame) {
        setSelectedGame(undefined);
      }
      return;
    }
    const updatedGame = selectedGame ? slateWeekGames.find((game) => game.id === selectedGame.id) : slateWeekGames[0];
    if (!deepEqual(updatedGame, selectedGame)) {
      setSelectedGame(updatedGame);
    }
  }, [slateWeekGames, selectedGame, sport]);

  // Track game configuration changes
  useEffect(() => {
    if (!firebase || !selectedGame) return;

    logAnalyticsEvent(firebase, user, AnalyticsEvents.CONFIGURE_SQUARES_GAME, {
      sport_type: sport,
      board_size: size,
      reshuffle_type: changeTargetScores,
      in_room: !!room,
      team_slate: teamSlate,
      game_start_time: selectedGame.startTimestamp,
      payouts_type: periodPayoutsKey,
    });
  }, [firebase, user, sport, size, changeTargetScores, room, teamSlate, selectedGame, periodPayoutsKey]);

  function setFootballGameId(id: string) {
    if (!firebase) return;

    const newGame = slateWeekGames?.find((game) => game.id === id);
    if (newGame) {
      logAnalyticsEvent(firebase, user, AnalyticsEvents.SELECT_SPORTS_GAME, {
        sport_type: sport,
        game_id: id,
        team_slate: teamSlate,
        game_start_time: newGame.startTimestamp,
      });
      setSelectedGame(newGame);
    }
  }

  useEffect(() => {
    setNewGameData(!selectedGame ? undefined : _makeGameData(selectedGame, size, changeTargetScores));
  }, [selectedGame, size, changeTargetScores, setNewGameData]);

  function setTeamSlateFn(teamSlate: TeamSlate) {
    if (!firebase) return;

    logAnalyticsEvent(firebase, user, AnalyticsEvents.CHANGE_TEAM_SLATE, {
      sport_type: sport,
      new_slate: teamSlate,
    });

    setTeamSlate(teamSlate);
    window.history.replaceState(null, "", `/games/new/football-squares/slate/${teamSlate}`);
  }

  // Update state to use anchorEl
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!firebase) return;

    logAnalyticsEvent(firebase, user, AnalyticsEvents.OPEN_SQUARES_SETTINGS, {
      sport_type: sport,
      current_size: size,
      current_reshuffle: changeTargetScores,
      in_room: !!room,
    });

    // If the click didn't come from the IconButton, find the IconButton and use its position
    if (!(event.target as HTMLElement).closest("button")) {
      const iconButton = (event.currentTarget as HTMLElement).querySelector("button");
      if (iconButton) {
        const rect = iconButton.getBoundingClientRect();
        const newEvent = { ...event, currentTarget: iconButton };
        newEvent.clientX = rect.left + rect.width / 2;
        newEvent.clientY = rect.top + rect.height / 2;
        setAnchorEl(iconButton);
        return;
      }
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
    logAnalyticsEvent(firebase, user, AnalyticsEvents.DEMO_SQUARES_GAME_PLAYBACK, {
      action: isPlaying ? "pause" : "play",
      sport: sport,
    });
  };

  return (
    <>
      {sport === "Football" && (
        <SlateGamesPicker
          teamSlate={teamSlate ?? TeamSlate.NFL}
          title="Choose any NFL or NCAA game"
          description="Sportsball can run a squares game for any football game! Choose a game below."
          setTeamSlate={setTeamSlateFn}
          slateWeekGames={slateWeekGames ?? []}
          setSlateWeekGames={setSlateWeekGames}
        />
      )}
      {sport === "Baseball" && (!selectedGame || selectedGame.type === "Baseball") && (
        <>
          <Typography variant="h5" component="h2">
            Choose any MLB Game
          </Typography>
          <DayGamePicker sport="Baseball" selectedGame={selectedGame} setSelectedGame={setSelectedGame} />
        </>
      )}
      {sport === "Basketball" && (!selectedGame || selectedGame.type === "Basketball") && (
        <>
          <Typography variant="h5" component="h2">
            Choose any NBA Game
          </Typography>
          <DayGamePicker sport="Basketball" selectedGame={selectedGame} setSelectedGame={setSelectedGame} />
        </>
      )}
      {selectedGame && slateWeekGames && (
        <TextField
          select
          fullWidth
          id="game"
          label={`${teamSlate} Game`}
          value={selectedGame?.id ?? ""}
          SelectProps={{
            MenuProps: {
              style: {
                maxHeight: 400,
              },
            },
          }}
          onChange={(e) => setFootballGameId(e.target.value)}
        >
          {slateWeekGames.map(({ startTimestamp, week, id }) => {
            const date = new Intl.DateTimeFormat(undefined, {
              weekday: "short",
              hour: "numeric",
              minute: "numeric",
            }).format(startTimestamp);
            return (
              <MenuItem key={id} value={id}>
                {week} - {date}
              </MenuItem>
            );
          })}
        </TextField>
      )}

      {/* Settings Dialog */}
      <SquaresConfigMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        size={size}
        setSize={setSize}
        changeTargetScores={changeTargetScores}
        setChangeTargetScores={setChangeTargetScores}
        periodPayoutsKey={periodPayoutsKey}
        setPeriodPayoutsKey={setPeriodPayoutsKey}
        room={room}
        sport={sport}
      />

      {/* Demo/Settings */}
      {selectedGame && newGameData && (
        <>
          <Box display="flex" alignItems="center" onClick={handleClick}>
            <Typography variant="h5" component="h2">
              Settings
            </Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation(); // Prevent double-triggering
                handleClick(e);
              }}
              color="primary"
              aria-label="open settings"
            >
              <SettingsIcon />
            </IconButton>
          </Box>
          <Typography>
            Board Size: {size === 25 ? "5x5" : "10x10"} • Shift digits:{" "}
            {changeTargetScores === "SameAllGame"
              ? "Never"
              : changeTargetScores === "ChangeEveryOtherPeriod"
              ? sport === "Baseball"
                ? "Top of inning"
                : "At halftime"
              : sport === "Baseball"
              ? "Each half inning"
              : "Each quarter"}
            {room?.useChips && ` • Payouts: ${periodPayoutsKey}`}
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="h5" component="h2">
                Demo Game
              </Typography>
              <IconButton
                onClick={togglePlayPause}
                sx={{
                  ml: 1,
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 1,
                  padding: "2px 8px",
                  backgroundColor: "primary.main",
                  color: "primary.contrastText",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                  },
                }}
              >
                {isPlaying ? <PauseIcon sx={{ fontSize: 20 }} /> : <PlayArrowIcon sx={{ fontSize: 20 }} />}
              </IconButton>
            </Box>
          </Box>
          <Box onClick={togglePlayPause} sx={{ cursor: "pointer" }}>
            <DemoSquaresGame gameData={newGameData} isPlaying={isPlaying} />
          </Box>
        </>
      )}
    </>
  );
}
