import { ReactNode } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Stack from "@mui/material/Stack";

type FullSizeCardProps = BoxProps & {
  children: ReactNode;
  spacing?: number;
};

export default function FullSizeCard({ children, spacing = 2, ...props }: FullSizeCardProps) {
  return (
    <Box width="100%" marginBottom={spacing / 2} marginTop={spacing / 2} {...props}>
      <Stack width="100%" spacing={spacing} sx={{ borderRadius: 2, bgcolor: "white", padding: 2 }}>
        {children}
      </Stack>
    </Box>
  );
}
