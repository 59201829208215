import { createContext, useContext, useEffect, useState } from "react";

import { SomeGameData } from "@sportsball/shared";

import { useUser } from "@/context/useUser";

// Create the context
type GamesContextType = {
  games: SomeGameData[] | undefined;
};

const GamesContext = createContext<GamesContextType | undefined>(undefined);

// Create the provider component
export function GamesProvider({ children }: { children: React.ReactNode }) {
  const { firebase, user } = useUser();
  const [games, setGames] = useState<SomeGameData[] | undefined>();
  const uid = user?.uid;

  useEffect(() => {
    if (!firebase || !uid) {
      return;
    }
    const { collection, onSnapshot, query, where } = firebase.firestorePackage;
    const gamesCollection = collection(firebase.firestore, "games");
    const q = query(gamesCollection, where("userRefs", "array-contains", uid));
    return onSnapshot(q, (snapshot) => {
      const games: SomeGameData[] = [];
      snapshot.forEach((doc) => {
        games.push(SomeGameData.parse(doc.data()));
      });
      setGames(games);
    });
  }, [firebase, uid]);

  return <GamesContext.Provider value={{ games }}>{children}</GamesContext.Provider>;
}

// Create the hook to use the context
export function useUserGames() {
  const context = useContext(GamesContext);
  if (context === undefined) {
    throw new Error("useUserGames must be used within a GamesProvider");
  }
  return context.games;
}

export function splitUpGames(games: SomeGameData[] | undefined, { uid }: { uid: string }) {
  const hosting: SomeGameData[] = [];
  const enteredNotHosting: SomeGameData[] = [];
  if (games) {
    for (const game of games) {
      if (game.uid === uid) {
        hosting.push(game);
      } else {
        enteredNotHosting.push(game);
      }
    }
    hosting.sort((a, b) => b.startTimestamp - a.startTimestamp);
    enteredNotHosting.sort((a, b) => b.startTimestamp - a.startTimestamp);
  }

  return { hosting, enteredNotHosting };
}
