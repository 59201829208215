import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import AddIcon from "@mui/icons-material/Add";

import { BasicEntryData, SquaresGameData } from "@sportsball/shared";

import { useUser } from "@/context/useUser";

import ConfirmDialog from "@/components/ConfirmDialog";
import SquaresBoardEditor from "@/components/squares/SquaresBoardEditor";
import SquaresBoard from "@/components/squares/SquaresBoard";

import { lockSquaresGame } from "@/cloudFunctions";
import { GameRoomData } from "@/components/GameContainer";

export default function SquaresGame({
  gameId,
  game,
  gameOn,
  roomData,
  pregameEntries,
}: {
  gameId: string;
  game: SquaresGameData;
  gameOn: boolean;
  roomData: GameRoomData | undefined;
  pregameEntries?: Partial<Record<string, BasicEntryData>>;
}) {
  const { firebase, user } = useUser();
  const [editingGame, setEditingGame] = useState(false);
  const [initials, setInitials] = useState<string | undefined>();
  const [canJoin, setCanJoin] = useState(false);
  const [showConfirmLockGame, setShowConfirmLockGame] = useState(false);
  const uid = user?.uid;
  const { size } = game;

  const gameLocked = Boolean(game.targetScores);
  const pregameCellCount = Object.keys(pregameEntries ?? {}).length;

  useEffect(() => {
    setCanJoin(!gameLocked && pregameCellCount < size);
  }, [pregameCellCount, size, gameLocked]);

  useEffect(() => {
    if (gameLocked && editingGame) {
      setEditingGame(false);
    }
  }, [gameLocked, editingGame]);

  useEffect(() => {
    if (initials === undefined && user?.type === "signedIn") {
      setInitials(user.preferredInitials);
    }
  }, [user, initials]);

  const hasPregameEntry = uid && pregameEntries && Object.values(pregameEntries).find((entry) => entry!.uid === uid);

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Box onClick={gameLocked ? undefined : () => setEditingGame(true)} sx={gameLocked ? {} : { cursor: "pointer" }}>
          <SquaresBoard game={game} gameOn={gameOn} pregameEntries={pregameEntries} />
        </Box>
        {!gameLocked && (
          <Stack direction="row" justifyContent="center" gap={2}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setEditingGame(true)}
              disabled={editingGame || gameLocked}
            >
              {hasPregameEntry ? "Edit Entries" : "Join Game"}
            </Button>
            {firebase && game.uid === uid && (
              <Button
                variant="contained"
                onClick={() => {
                  if (canJoin) {
                    setShowConfirmLockGame(true);
                  } else {
                    lockSquaresGame(firebase, { gameId }).catch(alert);
                  }
                }}
                disabled={!hasPregameEntry}
              >
                LOCK GAME
              </Button>
            )}
          </Stack>
        )}
      </Stack>
      {firebase && (
        <ConfirmDialog
          prompt="This game still has empty squares. Are you sure you want to lock this game?"
          confirmText="Lock"
          cancelText="Cancel"
          open={showConfirmLockGame}
          confirmFn={() => void lockSquaresGame(firebase, { gameId }).catch(alert)}
          closeDialog={() => setShowConfirmLockGame(false)}
        />
      )}
      <SquaresBoardEditor
        game={game}
        roomData={roomData}
        gameId={gameId}
        pregameEntries={pregameEntries}
        show={editingGame}
        doneEditing={() => setEditingGame(false)}
        initials={initials ?? ""}
        setInitials={setInitials}
      />
    </>
  );
}
