import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// MUI Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// MUI Icons
import ClearIcon from "@mui/icons-material/Clear";

import {
  CreateGameParameters,
  CreatePickemGameParameters,
  CreateSquaresGameParameters,
  GameType,
  getGamePath,
  Sport,
} from "@sportsball/shared";

import { useUser } from "@/context/useUser";
import SignInDialog from "@/components/SignInDialog";
import FullSizeCard from "@/components/FullSizeCard";

import { createGame, wakeUpFunctions } from "@/cloudFunctions";
import { IncomingCreateGameMessage, sendNewGameMessage } from "@/flutter/messageHandlers";
import { useUserRooms } from "@/hooks/userRoomsHook";
import NewSquaresGame, { NewSquaresGameData } from "./squares/NewSquaresGame";
import NewPickemGame, { NewPickemGameData } from "./NewPickemGame";
import deepEqual from "deep-equal";
import { AnalyticsEvents, logAnalyticsEvent } from "@/lib/analytics";

export type PartialCreateGameParameters =
  | Omit<CreatePickemGameParameters, "id" | "name" | "description">
  | Omit<CreateSquaresGameParameters, "id" | "name" | "description">;

export type GetCreateGameData = () => PartialCreateGameParameters;

export interface GameTypeStrings {
  gameTypeDescription: string;
  longName: string;
}

const gameTypeStrings: Record<GameType, Record<Sport, GameTypeStrings>> = {
  Squares: {
    Football: {
      gameTypeDescription:
        "Compete with friends by picking scores for each quarter of an NFL or NCAA football game. Choose any game and get started!",
      longName: "NFL Squares",
    },
    Baseball: {
      gameTypeDescription:
        "Compete with friends by picking scores for each inning of an MLB game. Choose any game and get started!",
      longName: "MLB Squares",
    },
    Basketball: {
      gameTypeDescription:
        "Compete with friends by picking scores for each quarter of an NBA game. Choose any game and get started!",
      longName: "NBA Squares",
    },
  },
  Pickem: {
    Baseball: {
      gameTypeDescription:
        "Pick'em pools are a fun way to compete against your friends to see has the best ability to predict the results of MLB games. Choose your slate games and game type below and have fun!",
      longName: "MLB Pick'em",
    },
    Basketball: {
      gameTypeDescription:
        "Pick'em pools are a fun way to compete against your friends to see has the best ability to predict the results of NBA games. Choose your slate games and game type below and have fun!",
      longName: "NBA Pick'em",
    },
    Football: {
      gameTypeDescription:
        "Pick'em pools are a fun way to compete against your friends to see has the best ability to predict the results of NFL or NCAA football games. Choose your slate games and game type below and have fun!",
      longName: "NFL Pick'em",
    },
  },
};

function _isInWebview() {
  return window.location.pathname.startsWith("/webview");
}

export default function NewGame({ gameType, sport }: { gameType: "Pickem" | "Squares"; sport: Sport }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inRoomParam = searchParams.get("inRoom");
  const { firebase, user } = useUser();
  const [signInDialogOpen, setSignInDialogOpen] = useState(false);

  const { longName, gameTypeDescription } = gameTypeStrings[gameType][sport];

  const [gameName, setGameName] = useState(longName);
  const [gameDescription, setGameDescription] = useState<string>("");

  const [inRoom, setInRoom] = useState<string | undefined>();
  const [chipsPerEntry, setChipsPerEntry] = useState<number | undefined>();

  const [newGameData, _setNewGameData] = useState<NewSquaresGameData | NewPickemGameData | undefined>();

  const [isCreating, setIsCreating] = useState(false);

  const rooms = useUserRooms();
  const room = rooms?.find((room) => room.roomId === inRoom);

  const setNewGameData = useCallback(
    (withNewGameData: NewSquaresGameData | NewPickemGameData | undefined) => {
      if (!deepEqual(newGameData, withNewGameData)) {
        _setNewGameData(withNewGameData);
      }
    },
    [newGameData]
  );

  // one time set the inRoom from possible `inRoom` url parameter. The UI that is connected to setInRoom
  // should also be removed
  useEffect(() => {
    if (inRoomParam) {
      setInRoom(inRoomParam);
    }
  }, [inRoomParam]);

  useEffect(() => {
    if (!room || !room.useChips || chipsPerEntry !== undefined) {
      return;
    }
    setChipsPerEntry(1);
  }, [room, chipsPerEntry]);

  useEffect(() => {
    if (firebase) {
      wakeUpFunctions(firebase);
    }
  }, [firebase]);

  useEffect(() => {
    window.receiveNewGameMessageImpl = (message: IncomingCreateGameMessage) => {
      console.log("Received message", message);
      if (message.action === "createGame") {
        console.log("Creating game");
        const submitButton = document.querySelector('button[type="submit"]');
        if (submitButton) {
          console.log("Clicking submit button");
          (submitButton as HTMLButtonElement).click();
        }
        return;
      }
    };

    return () => {
      window.receiveNewGameMessageImpl = undefined;
    };
  }, []);

  function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    if (!user || !firebase || !newGameData) {
      sendNewGameMessage({
        status: "error",
        message: "Not ready to create game",
      });
      throw new Error("Cannot submit form");
    }
    event.preventDefault();
    setIsCreating(true);

    const createGameParameters: CreateGameParameters = {
      name: gameName,
      description: gameDescription,
      ...newGameData,
      ...(inRoom && { inRoom }),
      ...(chipsPerEntry && { chipsPerEntry }),
    };

    createGame(firebase, createGameParameters)
      .then((gameId) => {
        // Track successful game creation
        logAnalyticsEvent(firebase, user, AnalyticsEvents.CREATE_GAME, {
          game_type: createGameParameters.type,
          sport_type: sport,
          in_room: !!createGameParameters.inRoom,
          chips_per_entry: createGameParameters.chipsPerEntry,
          has_description: !!createGameParameters.description,
          in_webview: _isInWebview(),
        });

        sendNewGameMessage({
          status: "success",
          gameId: gameId,
        });
        if (!_isInWebview()) {
          navigate(getGamePath({ id: gameId }));
        }
      })
      .catch((e) => {
        // Track game creation errors
        logAnalyticsEvent(firebase, user, AnalyticsEvents.ERROR_OCCURRED, {
          error_type: "game_creation_error",
          error_message: e instanceof Error ? e.message : String(e),
          game_type: gameType,
          sport_type: sport,
        });

        console.error("Error creating game", e);
        sendNewGameMessage({
          status: "error",
          message: e instanceof Error ? e.message : String(e),
        });
        alert(e);
        setIsCreating(false);
      });
  }

  const hostingRooms = rooms?.filter((room) => room.uid === user?.uid);

  return (
    <>
      <Helmet>
        <title>{`Create a new ${longName} pool | Sportsball`}</title>
        <meta
          name="description"
          content={`Sportsball is a place to play NFL and NCAA pick'em and squares pools with friends online. Here you can quickly start your own ${longName} pool and invite your friends to play.`}
        />
      </Helmet>
      <form onSubmit={handleSubmit}>
        <FullSizeCard>
          <h1>{`Create a new ${longName} pool`}</h1>
          <p>{gameTypeDescription}</p>
          {gameType === "Squares" && (!newGameData || newGameData.type === "Squares") && (
            <NewSquaresGame sport={sport} room={room} newGameData={newGameData} setNewGameData={setNewGameData} />
          )}
          {gameType === "Pickem" && <NewPickemGame setNewGameData={setNewGameData} />}
        </FullSizeCard>

        <FullSizeCard>
          <Typography variant="h5" component="h2">
            Name your Game
          </Typography>
          <TextField
            id="game-name"
            label="Game Name"
            variant="outlined"
            value={gameName ?? ""}
            onChange={(e) => setGameName(e.target.value)}
            slotProps={{
              input: {
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setGameName("");
                      document.getElementById("game-name")?.focus();
                    }}
                    edge="end"
                  >
                    <ClearIcon sx={{ backgroundColor: "#e0e0e0", borderRadius: "50%", padding: "4px" }} />
                  </IconButton>
                ),
              },
            }}
            disabled={isCreating}
          />
          {!inRoomParam && hostingRooms && hostingRooms.length > 0 && (
            <FormControl fullWidth>
              <InputLabel id="room-select-label">Add to Room</InputLabel>
              <Select
                labelId="room-select-label"
                id="room-id"
                label="Add to Room"
                value={inRoom ?? ""}
                onChange={(e) => {
                  setInRoom(e.target.value === "" ? undefined : e.target.value);
                }}
                disabled={isCreating}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {hostingRooms.map((room) => (
                  <MenuItem key={room.roomId} value={room.roomId}>
                    {room.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {room && room.useChips && (
            <FormControl fullWidth>
              <InputLabel id="chips-per-entry-label">Chips per entry</InputLabel>
              <Select
                labelId="chips-per-entry-label"
                id="chips-per-entry"
                value={chipsPerEntry ?? ""}
                onChange={(e) =>
                  setChipsPerEntry(e.target.value === "" ? undefined : parseInt(e.target.value as string))
                }
                disabled={isCreating}
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="10">10</MenuItem>
              </Select>
            </FormControl>
          )}
          <p>Include a description of special rules for your game.</p>
          <TextField
            multiline
            rows={2}
            fullWidth={true}
            id="game-description"
            label="Game Description"
            variant="outlined"
            value={gameDescription}
            onChange={(e) => setGameDescription(e.target.value)}
            disabled={isCreating}
          />
        </FullSizeCard>

        <Box
          sx={
            _isInWebview()
              ? {
                  position: "absolute",
                  opacity: 0,
                  pointerEvents: "auto", // ensures the button remains clickable
                }
              : undefined
          }
        >
          <FullSizeCard>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Button
                type="submit"
                variant={newGameData ? "contained" : "outlined"}
                disabled={!newGameData || isCreating}
              >
                {isCreating ? "Creating..." : "Create Game"}
              </Button>
            </Stack>
          </FullSizeCard>
        </Box>
      </form>
      {firebase && <SignInDialog open={signInDialogOpen} closeDialog={() => setSignInDialogOpen(false)} />}
    </>
  );
}
