import { ChangeTargetScores, RoomData, Sport } from "@sportsball/shared";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { useState } from "react";

const _periodPayoutSplits = [
  { name: "Big Half & Final", payouts: [3, 9, 3, 10] },
  { name: "Big Final", payouts: [3, 4, 3, 15] },
  { name: "Even", payouts: [6, 6, 6, 7] },
];

interface SquaresConfigMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  size: 25 | 100;
  setSize: (size: 25 | 100) => void;
  changeTargetScores: ChangeTargetScores;
  setChangeTargetScores: (changeTargetScores: ChangeTargetScores) => void;
  periodPayoutsKey: string;
  setPeriodPayoutsKey: (key: string) => void;
  room?: RoomData;
  sport: Sport;
}

export default function SquaresConfigMenu({
  anchorEl,
  open,
  onClose,
  size,
  setSize,
  changeTargetScores,
  setChangeTargetScores,
  periodPayoutsKey,
  setPeriodPayoutsKey,
  room,
  sport,
}: SquaresConfigMenuProps) {
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoDialogContent, setInfoDialogContent] = useState("");
  const [infoDialogTitle, setInfoDialogTitle] = useState("");

  const handleInfoClick = (title: string, content: string) => {
    setInfoDialogTitle(title);
    setInfoDialogContent(content);
    setInfoDialogOpen(true);
  };

  const handleSizeChange = (newSize: 25 | 100) => {
    setSize(newSize);
    onClose();
  };

  const handleTargetScoresChange = (newValue: ChangeTargetScores) => {
    setChangeTargetScores(newValue);
    onClose();
  };

  const handlePayoutsChange = (newValue: string) => {
    setPeriodPayoutsKey(newValue);
    onClose();
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            mt: 1,
            width: 320,
            maxHeight: "80vh",
          },
        }}
      >
        <Box p={2}>
          {/* Board Size */}
          <Box mb={3}>
            <Box display="flex" alignItems="center">
              <Typography variant="h6">Board Size</Typography>
              <IconButton
                size="small"
                sx={{ ml: 1 }}
                onClick={() =>
                  handleInfoClick(
                    "Board Size",
                    "For a simpler game with more balanced odds, choose a 5x5 board. 5x5 boards assign two scores to each row and column. 10x10 boards are available for the classic squares experience."
                  )
                }
              >
                <InfoIcon fontSize="small" />
              </IconButton>
            </Box>
            <ButtonGroup variant="contained" size="large" fullWidth sx={{ mt: 1 }}>
              <Button variant={size === 25 ? "contained" : "outlined"} onClick={() => handleSizeChange(25)}>
                5x5
              </Button>
              <Button variant={size === 100 ? "contained" : "outlined"} onClick={() => handleSizeChange(100)}>
                10x10
              </Button>
            </ButtonGroup>
          </Box>

          {/* Shifting Target Digits */}
          <Box mb={3}>
            <Box display="flex" alignItems="center">
              <Typography variant="h6">Shift Target Digits</Typography>
              <IconButton
                size="small"
                sx={{ ml: 1 }}
                onClick={() =>
                  handleInfoClick(
                    "Shift Target Digits",
                    "To mix up the action, the target digits can be shifted during the game."
                  )
                }
              >
                <InfoIcon fontSize="small" />
              </IconButton>
            </Box>
            <TextField
              select
              fullWidth
              value={changeTargetScores}
              onChange={(e) => handleTargetScoresChange(e.target.value as ChangeTargetScores)}
              label="Shift target digits"
              sx={{ mt: 1 }}
            >
              <MenuItem value="SameAllGame">Never</MenuItem>
              <MenuItem value="ChangeEveryOtherPeriod">
                {sport === "Baseball" ? "Top of each inning" : "At halftime"}
              </MenuItem>
              <MenuItem value="ChangeEveryPeriod">
                {sport === "Baseball" ? "Each half inning" : "Each quarter"}
              </MenuItem>
            </TextField>
          </Box>

          {/* Chip Payouts */}
          {room?.useChips && (
            <Box mb={3}>
              <Box display="flex" alignItems="center">
                <Typography variant="h6">Chip Payouts</Typography>
                <IconButton
                  size="small"
                  sx={{ ml: 1 }}
                  onClick={() => handleInfoClick("Chip Payouts", "Choose how to distribute chips across periods.")}
                >
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Box>
              <TextField
                select
                fullWidth
                value={periodPayoutsKey}
                onChange={(e) => handlePayoutsChange(e.target.value)}
                label="Payout distribution"
                sx={{ mt: 1 }}
              >
                {_periodPayoutSplits.map((split) => (
                  <MenuItem key={split.name} value={split.name}>
                    {split.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )}

          <Box display="flex" justifyContent="flex-end">
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          </Box>
        </Box>
      </Menu>

      <Dialog
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
        aria-labelledby="info-dialog-title"
        aria-describedby="info-dialog-description"
      >
        <DialogTitle id="info-dialog-title">{infoDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="info-dialog-description">{infoDialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInfoDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
