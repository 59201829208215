import { BaseballGame, BasketballGame, FootballGame } from "@sportsball/shared";

type BaseballDemoFrame = Pick<BaseballGame, "status" | "periodScores">;
type FootballDemoFrame = Pick<FootballGame, "status" | "timer" | "periodScores">;
type BasketballDemoFrame = Pick<BasketballGame, "status" | "timer" | "periodScores">;

type SportDemoScript = {
  Baseball: BaseballDemoFrame[];
  Football: FootballDemoFrame[];
  Basketball: BasketballDemoFrame[];
};

export const DEMO_SCRIPTS: SportDemoScript = {
  Baseball: [
    { status: "IN1", periodScores: [0] },
    { status: "IN1", periodScores: [0, 0] },
    { status: "IN2", periodScores: [0, 0, 0] },
    { status: "IN2", periodScores: [0, 0, 1] },
    { status: "IN2", periodScores: [0, 0, 1, 0] },
    { status: "IN3", periodScores: [0, 0, 1, 0, 0] },
    { status: "IN3", periodScores: [0, 0, 1, 0, 1] },
    { status: "IN3", periodScores: [0, 0, 1, 0, 1, 0] },
    { status: "IN3", periodScores: [0, 0, 1, 0, 1, 1] },
    { status: "IN4", periodScores: [0, 0, 1, 0, 1, 1, 0] },
    { status: "IN4", periodScores: [0, 0, 1, 0, 1, 1, 0, 0] },
    { status: "IN4", periodScores: [0, 0, 1, 0, 1, 1, 0, 2] },
    { status: "IN5", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 0] },
    { status: "IN5", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 1] },
    { status: "IN5", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 1, 0] },
    { status: "IN6", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 1, 0, 0] },
    { status: "IN6", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 1, 0, 2] },
    { status: "IN6", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 1, 0, 2, 0] },
    { status: "IN7", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 1, 0, 2, 0, 0] },
    { status: "IN7", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 1, 0, 2, 0, 1] },
    { status: "IN7", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 1, 0, 2, 0, 1, 0] },
    { status: "IN8", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 1, 0, 2, 0, 1, 0, 0] },
    { status: "IN8", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 1, 0, 2, 0, 1, 0, 0, 0] },
    { status: "IN9", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 1, 0, 2, 0, 1, 0, 0, 0, 0] },
    { status: "IN9", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 1, 0, 2, 0, 1, 0, 0, 0, 1] },
    { status: "IN9", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 1, 0, 2, 0, 1, 0, 0, 0, 1, 0] },
    { status: "FT", periodScores: [0, 0, 1, 0, 1, 1, 0, 2, 1, 0, 2, 0, 1, 0, 0, 0, 1, 2] }, // Bottom 9 - walk-off 2 runs
  ],
  Basketball: [
    { status: "Q1", timer: "2:54", periodScores: [{ away: 18, home: 10 }] },
    {
      status: "Q2",
      timer: "10:34",
      periodScores: [
        { away: 21, home: 15 },
        { away: 4, home: 9 },
      ],
    },
    {
      status: "Q3",
      timer: "7:14",
      periodScores: [
        { away: 21, home: 15 },
        { away: 18, home: 22 },
        { away: 12, home: 8 },
      ],
    },
    {
      status: "Q4",
      timer: "3:45",
      periodScores: [
        { away: 21, home: 15 },
        { away: 18, home: 22 },
        { away: 24, home: 16 },
        { away: 10, home: 12 },
      ],
    },
    {
      status: "FT",
      periodScores: [
        { away: 21, home: 15 },
        { away: 18, home: 22 },
        { away: 24, home: 16 },
        { away: 20, home: 17 },
      ],
    },
  ],
  Football: [
    // Super Bowl LIX (February 2025) - Philadelphia Eagles vs Kansas City Chiefs
    { status: "Q1", timer: "15:00", periodScores: [{ away: 0, home: 0 }] },
    { status: "Q1", timer: "6:15", periodScores: [{ away: 7, home: 0 }] }, // Eagles TD (Hurts 1-yd rush) + XP

    // Q2 scoring
    {
      status: "Q2",
      timer: "15:00",
      periodScores: [
        { away: 7, home: 0 },
        { away: 0, home: 0 },
      ],
    }, // Start of Q2
    {
      status: "Q2",
      timer: "8:38",
      periodScores: [
        { away: 7, home: 0 },
        { away: 3, home: 0 },
      ],
    }, // Eagles FG (Elliott, 48-yd)
    {
      status: "Q2",
      timer: "7:03",
      periodScores: [
        { away: 7, home: 0 },
        { away: 10, home: 0 },
      ],
    }, // Eagles TD (DeJean 38-yd interception return) + XP
    {
      status: "Q2",
      timer: "1:35",
      periodScores: [
        { away: 7, home: 0 },
        { away: 17, home: 0 },
      ],
    }, // Eagles TD (Brown 12-yd catch from Hurts) + XP

    // Q3 scoring
    {
      status: "Q3",
      timer: "15:00",
      periodScores: [
        { away: 7, home: 0 },
        { away: 17, home: 0 },
        { away: 0, home: 0 },
      ],
    }, // Start of Q3
    {
      status: "Q3",
      timer: "5:18",
      periodScores: [
        { away: 7, home: 0 },
        { away: 17, home: 0 },
        { away: 3, home: 0 },
      ],
    }, // Eagles FG (Elliott, 29-yd)
    {
      status: "Q3",
      timer: "2:40",
      periodScores: [
        { away: 7, home: 0 },
        { away: 17, home: 0 },
        { away: 10, home: 0 },
      ],
    }, // Eagles TD (Smith 46-yd catch from Hurts) + XP
    {
      status: "Q3",
      timer: "0:34",
      periodScores: [
        { away: 7, home: 0 },
        { away: 17, home: 0 },
        { away: 10, home: 6 },
      ],
    }, // Chiefs TD (Worthy 24-yd catch from Mahomes) + failed 2pt

    // Q4 scoring
    {
      status: "Q4",
      timer: "15:00",
      periodScores: [
        { away: 7, home: 0 },
        { away: 17, home: 0 },
        { away: 10, home: 6 },
        { away: 0, home: 0 },
      ],
    }, // Start of Q4
    {
      status: "Q4",
      timer: "9:51",
      periodScores: [
        { away: 7, home: 0 },
        { away: 17, home: 0 },
        { away: 10, home: 6 },
        { away: 3, home: 0 },
      ],
    }, // Eagles FG (Elliott, 48-yd)
    {
      status: "Q4",
      timer: "8:01",
      periodScores: [
        { away: 7, home: 0 },
        { away: 17, home: 0 },
        { away: 10, home: 6 },
        { away: 6, home: 0 },
      ],
    }, // Eagles FG (Elliott, 50-yd)
    {
      status: "Q4",
      timer: "2:54",
      periodScores: [
        { away: 7, home: 0 },
        { away: 17, home: 0 },
        { away: 10, home: 6 },
        { away: 6, home: 8 },
      ],
    }, // Chiefs TD (Hopkins 7-yd catch from Mahomes) + 2pt
    {
      status: "Q4",
      timer: "1:48",
      periodScores: [
        { away: 7, home: 0 },
        { away: 17, home: 0 },
        { away: 10, home: 6 },
        { away: 6, home: 16 },
      ],
    }, // Chiefs TD (Worthy 50-yd catch from Mahomes) + 2pt

    // Final score: Eagles 40, Chiefs 22
    {
      status: "FT",
      periodScores: [
        { away: 7, home: 0 },
        { away: 17, home: 0 },
        { away: 10, home: 6 },
        { away: 6, home: 16 },
      ],
    },
  ],
};
