import { useFirebasePreUser } from "@/context/useFirebasePreUser";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { ScheduledBaseballGame, ScheduledBasketballGame } from "@sportsball/shared";
import { useEffect, useState } from "react";
import { z } from "zod";

type DayPickerSport = "Baseball" | "Basketball";

export default function DayGamePicker({
  sport,
  selectedGame,
  setSelectedGame,
}: {
  sport: DayPickerSport;
  selectedGame?: ScheduledBaseballGame | ScheduledBasketballGame;
  setSelectedGame: (game: ScheduledBaseballGame | ScheduledBasketballGame) => void;
}) {
  const league = sport === "Baseball" ? "MLB" : "NBA";
  const firebase = useFirebasePreUser();
  const [schedule, setSchedule] = useState<ScheduledBaseballGame[] | ScheduledBasketballGame[] | undefined>();

  function setGameId(id: string) {
    const newGame = schedule?.find((game) => game.id === id);
    if (newGame) {
      setSelectedGame(newGame);
    }
  }

  useEffect(() => {
    if (!firebase) {
      return;
    }
    const { onValue, ref } = firebase.databasePackage;
    const gamesRef = ref(firebase.database, `games/${sport.toLowerCase()}/${league}`);

    return onValue(gamesRef, (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }

      if (sport === "Baseball") {
        const scheduleData = z.record(z.string(), z.array(ScheduledBaseballGame)).parse(snapshot.val());

        const v = Object.values(scheduleData);
        const newSchedule = v.flatMap((games) => games).sort((a, b) => a.startTimestamp - b.startTimestamp);
        setSchedule(newSchedule);

        if (!selectedGame) {
          setSelectedGame(newSchedule[0]);
        }
      } else {
        const scheduleData = z.record(z.string(), z.array(ScheduledBasketballGame)).parse(snapshot.val());
        const v = Object.values(scheduleData);
        const newSchedule = v.flatMap((games) => games).sort((a, b) => a.startTimestamp - b.startTimestamp);
        setSchedule(newSchedule);

        if (!selectedGame) {
          setSelectedGame(newSchedule[0]);
        }
      }
    });
  }, [firebase, selectedGame, setSelectedGame, sport, league]);

  return !schedule || Object.keys(schedule).length === 0 ? null : (
    <TextField
      select
      fullWidth
      id="game"
      label={`${league} Game`}
      value={selectedGame?.id ?? ""}
      SelectProps={{
        MenuProps: {
          style: {
            maxHeight: 400,
          },
        },
      }}
      onChange={(e) => setGameId(e.target.value)}
    >
      {schedule.map((game) => {
        return (
          <MenuItem key={game.id} value={game.id}>
            {game.teams.away.shortName} @ {game.teams.home.shortName}
            {" - "}
            {new Date(game.startTimestamp).toLocaleDateString(undefined, { month: "short", day: "numeric" })}{" "}
            {new Date(game.startTimestamp).toLocaleTimeString(undefined, { hour: "numeric", minute: "2-digit" })}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
